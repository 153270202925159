<template>
  <div>
    <c-card title="추적검사 피대상자 정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="follwUpExam"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-field
            disabled
            :editable="editable"
            :data="follwUpExam"
            deptValue="deptCd"
            type="dept_user"
            label="피대상자"
            name="userId"
            v-model="follwUpExam.userId">
          </c-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            disabled
            :editable="editable"
            label="사번"
            name="empNo"
            v-model="follwUpExam.empNo">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            disabled
            :editable="editable"
            label="생년월일"
            name="birthDate"
            v-model="follwUpExam.birthDate">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            disabled
            :editable="editable"
            name="follwUpExamStandardOpinion"
            label="소견"
            v-model="follwUpExam.follwUpExamStandardOpinion"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            disabled
            codeGroupCd="HEA_FOLLW_UP_EXAM_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="heaFollwUpExamTypeCd"
            label="구분"
            v-model="follwUpExam.heaFollwUpExamTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            disabled
            :editable="editable"
            label="약복용여부"
            name="takeMedicine"
            v-model="follwUpExam.takeMedicine">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            disabled
            type="month"
            label="년월"
            name="follwUpExamYear"
            v-model="follwUpExam.yearMonth"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-text
            disabled
            :editable="editable"
            label="비고"
            name="remark"
            v-model="follwUpExam.remark">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="추적검사관리 결과"
      gridHeightAuto
      :merge="grid.merge"
      :columns="grid.columns"
      :data="follwUpExam.results"
      :editable="editable"
      :filtering="false"
      :usePaging="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      @table-data-change="tableDataChange"
    >
      <template slot="suffixTitle">
        <q-chip :color="getColor" text-color="white" icon="check" :label="follwUpExam.examResultName" />
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "follwUpExamMonthDetail",
  props: {
    popupParam: {
      type: Object,
      default: () => {
        return {
          heaFollwUpExamId: '',
          heaFollwUpExamYearId: '',
          heaFollwUpExamMonthId: '',
          follwUpExamStandardOpinion: '',
          month: '',
        }
      }
    }
  },
  data() {
    return {
      follwUpExam: {
        heaFollwUpExamMonthId: '',  // 추적검사관리 월 일련번호
        heaFollwUpExamId: '',  // 추적검사관리 일련번호
        heaFollwUpExamYearId: '',  // 년도별 추적검사관리 일련번호
        follwUpExamYear: '',
        yearMonth: '',  // 년월
        examResultName: '',  // 최종 결과 표시값
        follwUpExamStandardOpinion: null,  // 소견
        empNo: '',  // 피검사자id
        userId: '',  // 피검사자 사번
        userName: '',  // 피검사자명
        deptCd: '',  // 피검사자부서코드
        deptName: '',  // 피검사자부서명
        upDeptCd: '',  // 피검사자상위부서코드
        upDeptName: '',  // 피검사자상위부서명
        birthDate: '',  // 생년월일
        heaFollwUpExamTypeCd: null,  // 구분
        takeMedicine: '',  // 약복용여부
        remark: '',  // 비고
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        results: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'follwUpExamStandardOpinion', },
        ],
        columns: [
          {
            name: 'follwUpExamStandardOpinion',
            field: 'follwUpExamStandardOpinion',
            label: '소견',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'follwUpExamStandardClass',
            field: 'follwUpExamStandardClass',
            label: '분류',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'examResult',
            field: 'examResult',
            label: '결과',
            align: 'right',
            sortable: true,
            style: 'width: 150px',
            type: 'number'
          },
          {
            name: 'unit',
            field: 'unit',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
        ],
      },
      follwUpExamStandards: [],
      mappingType: 'PUT',
      editable: true,
      isSave: false,
      detailMonthUrl: '',
      detailUrl: '',
      listStandardUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaFollwUpExamMonthId)
    },
    getColor() {
      let color = 'grey';
      switch(this.follwUpExam.examResultName) {
        case '양호':
          color = 'green-7'
          break;
        case '주의':
          color = 'orange'
          break;
        case '위험':
          color = 'red-7'
          break;
        default:
          color = 'grey'
      }
      return color;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailMonthUrl = selectConfig.hea.fue.month.get.url
      this.detailUrl = selectConfig.hea.fue.get.url
      this.listStandardUrl = selectConfig.hea.fue.standard.list.url
      this.saveUrl = transactionConfig.hea.fue.month.update.url
      // code setting
      this.getStandards();
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailMonthUrl, this.popupParam.heaFollwUpExamMonthId);
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.extend(this.follwUpExam, _result.data);
        },);
      } else {
        /**
         * 월별 데이터가 없는 경우 사용자 정보만 불러오게 처리
         */
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaFollwUpExamId);
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.extend(this.follwUpExam, _result.data);
          this.$set(this.follwUpExam, 'yearMonth', `${this.follwUpExam.follwUpExamYear}-${this.$_.padStart(this.popupParam.month, 2, '0')}`)
        },);
      }
    },
    getStandards() {
      this.$http.url = this.listStandardUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        follwUpExamStandardOpinion: this.popupParam.follwUpExamStandardOpinion,
        useFlag: 'Y'
      }
      this.$http.request((_result) => {
        this.follwUpExamStandards = _result.data;
        if (!this.isOld) {
          this.follwUpExam.results = this.$_.map(_result.data, item => {
            return this.$_.extend(item, {
              heaFollwUpExamMonthResultId: '',  // 추적검사관리 월별 결과 일련번호
              heaFollwUpExamMonthId: '',  // 추적검사관리 월 일련번호
              heaFollwUpExamId: this.popupParam.heaFollwUpExamId,  // 추적검사관리 일련번호
              heaFollwUpExamYearId: this.popupParam.heaFollwUpExamYearId,  // 년도별 추적검사관리 일련번호
              follwUpExamStandardClass: item.follwUpExamStandardClass,  // 소견 분류
              examResult: '',  // 소견 분류에 따른 결과값
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C',
            })
          });
        }
      },);
    },
    tableDataChange() {
      let result = 1;
      this.$_.forEach(this.follwUpExam.results, resultItem => {
        if (result === 3) return false;
        let examResult = resultItem.examResult ? Number(this.$_.clone(resultItem.examResult)) : 0
        if (!examResult) return;
        this.$_.forEach(this.$_.filter(this.follwUpExamStandards, { follwUpExamStandardClass: resultItem.follwUpExamStandardClass, follwUpExamStandardOpinion: resultItem.follwUpExamStandardOpinion }), follwUpExamStandard => {
          if (result === 3) return false;
          if (follwUpExamStandard.goodFlag === 'Y' && result < 2) {
            if (examResult >= follwUpExamStandard.goodFrom && examResult < follwUpExamStandard.goodTo) {
              result = 1;
            }
          }
          if (follwUpExamStandard.cautionFlag === 'Y' && result < 3) {
            if (examResult >= follwUpExamStandard.cautionFrom && examResult < follwUpExamStandard.cautionTo) {
              result = 2;
            }
          }
          if (follwUpExamStandard.dangerFlag === 'Y') {
            if (examResult >= follwUpExamStandard.dangerFrom && examResult < follwUpExamStandard.dangerTo) {
              result = 3;
            }
          }
        })
      })
      
      if (result === 1) {
        this.follwUpExam.examResultName = '양호'
      } else if (result === 2) {
        this.follwUpExam.examResultName = '주의'
      } else if (result === 3) {
        this.follwUpExam.examResultName = '위험'
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.hea.fue.month.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.hea.fue.month.insert.url
        this.mappingType = 'POST'
      }
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {            
          this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaFollwUpExamMonthId', result.data)
      this.getDetail();
    },
  },
};
</script>
